function Settings() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 lg:py-5 mx-auto py-2 b-l m-h-600">
      <div className="mb-5">
        <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-300 dark:group-hover:text-white">
          Accounts
        </h3>
        <div className="mt-3">
          <a
            className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md focus:outline-none focus:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800"
            href="#"
          >
            <div className="p-4 md:p-5">
              <div className="flex gap-x-5 items-center">
                <div className="flex justify-center items-center size-[62px] rounded-full border-4 border-blue-50 bg-blue-100 dark:border-blue-900 dark:bg-blue-800">
                  <svg
                    className="shrink-0 size-6 text-blue-600 dark:text-blue-400"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
                    <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
                  </svg>
                </div>
                <div className="grow">
                  <h3 className="group-hover:text-blue-600 size-6 font-semibold text-gray-800 dark:group-hover:text-neutral-400 dark:text-neutral-200">
                    Email
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-neutral-500">
                    Connect your wallet to edit your Email address
                  </p>
                </div>
              </div>
              <div className="input-group mb-3 mt-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email not set"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                ></input>
                <button
                  className="btn btn-verify btn-outline-secondary  text-white"
                  type="button"
                  id="button-addon2"
                >
                  Save Settings
                </button>
              </div>
            </div>
          </a>
        </div>

        {/* <p className="mt-5 text-gray-400 dark:text-neutral-400">
        In 2021, after successfully crowdfunding for the Infinxte album I
        launched an entry that I continuously updated with achievements,
        milestones, metrics, and more!
      </p> */}
      </div>
    </div>
  );
}

export default Settings;
