import { Routes, Route } from "react-router-dom";
import "./App.css";
import Write from "./Components/write";
import Layout from "./Components/Layout/layout";
import Dashboard from "./Components/dashboard";
import FeatureDetail from "./Components/featuredetail";
import Header from "./Components/Layout/header";
import { Web3ReactProvider, initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { useState } from "react";



function App() {

  const [metaMask, metaMaskHooks] = initializeConnector((actions) => new MetaMask({ actions }));
  const connectors = [
    [metaMask, metaMaskHooks]
  ];
  const [userIdentity, setUserIdentity] = useState('');
  const setUserIdFromHeader = (id) => {
    console.log('Setting userId from header:', id);
    setUserIdentity(id);
  }

  return (
    <div className="app">
      <Web3ReactProvider connectors={connectors}>
        <Header />
        <Routes>
          <Route path="/" element={<Layout />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/write" element={<Write />}></Route>
          <Route path="/featuredetail" element={<FeatureDetail />}></Route>
        </Routes>
      </Web3ReactProvider>
    </div>
  );
}

export default App;
