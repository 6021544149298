import axios from "axios";
import { useEffect, useState } from "react";

function Profile({ userIdentity }) {
  const [profile, setProfile] = useState({});
  const [bio, setBio] = useState("");

  useEffect(() => {
    const fetchUserProfile = async () => {
      console.log(`Fetching user profile`);
      try {
        const response = await axios.get(`api/users/${userIdentity}`);
        if (response.status === 200) {
          setProfile(response.data);
          setBio(response.data.bio);
        }
      } catch (error) {
        console.error('Error fetching subscribers:', error);
        setProfile({});
      }
    };

    fetchUserProfile();
  }, []);

  const handleUserProfileSave = async () => {
    console.log(`Updating user profile`);

    try {
      const response = await axios.post('api/update-profile', { bio: bio, userId: userIdentity });
      if (response.status === 200 || response.status === 201) {
        console.log(response.data);
      }
    } catch (error) {
      console.error('Error saving profile:', error);
    }

    const closeButton = document.querySelector(".profile-close");
    if (closeButton) {
      closeButton.click();
    }
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8 lg:py-5 mx-auto py-2 b-l m-h-600">
      <section>
        <div className="box1 box bg-white border mx-auto border-gray-200 rounded-xl shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
          <div className="content">
            <div className="image">
              <img
                src="https://i.postimg.cc/bryMmCQB/profile-image.jpg"
                alt="Profile Image"
              />
            </div>
            <div className="text mt-2">
              <p className="name">{profile.walletAddress?.substring(0, 7)}</p>
              <p className="job_discription">
                {bio}
              </p>
            </div>

            <div>
              <div>
                <button className="message btn-subscribe" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  Edit
                </button>
              </div>
            </div>

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header border-0">
                    <button type="button" class="btn-close profile-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body text-left">
                    <div class="mb-3">
                      <label htmlFor="exampleFormControlInput1" class="form-label fw-semibold">Upload image</label>
                      <input type="file" class="form-control" placeholder="name@example.com" />
                    </div>
                    <div class="mb-3">
                      <label htmlFor="exampleFormControlTextarea1" class="form-label fw-semibold">Description</label>
                      <textarea class="form-control" rows="3" onChange={(e) => setBio(e.target.value)}>{bio}</textarea>
                    </div>
                  </div>
                  <div class="modal-footer border-0">
                    <button type="button" class=" py-2 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-gray-500 text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 disabled:opacity-50 disabled:pointer-events-none" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class=" py-2 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-teal-500 text-white hover:bg-teal-600 focus:outline-none focus:bg-teal-600 disabled:opacity-50 disabled:pointer-events-none" onClick={handleUserProfileSave}>Save</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
}

export default Profile;
