import "../../App.css";
import Feature from "../feature";
import Footer from "./footer";
import Header from "./header";

function Layout() {
  return (
    <div>
      <main id="content" className="shrink-0">
        <div className="max-w-[85rem] min-h-[40rem] py-10 mx-auto px-4 sm:px-6 lg:px-8">
          <Feature />
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Layout;
