import React, { useEffect, useState } from "react";
import Enteries from "./entries";
import Settings from "./settings";
import Profile from "./profile";
import Subscribers from "./subcribers";
import axios from "axios";
function Inbox({ userIdentity }) {
  const [activeTab, setActiveTab] = useState("Enteries");
  const [subscribers, setSubscribers] = useState([]);
  const openPage = (pageName) => {
    setActiveTab(pageName);
  };

  const getTabClass = (tabName) => {
    return activeTab === tabName
      ? "tab_link flex items-center active"
      : "tab_link flex items-center";
  };

  useEffect(() => {
    const fetchSubscribers = async () => {
        console.log(`Fetching subscribers`);
        try {
          const response = await axios.get(`api/subscriptions/${userIdentity}/subscriptions`);
          if (response.status === 200) {
            setSubscribers(response.data);
          }
        } catch (error) {
          console.error('Error fetching subscribers:', error);
        }
    };

    fetchSubscribers();
  }, []);

  return (
    <main id="content" className="shrink-0">
      <div className="max-w-9xl max-w-[85rem] mx-auto min-h-[40rem] px-2 sm:px-6 lg:px-8">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <div className="fpt_btn_container group flex flex-col h-full tab-custom">
                <button
                  className={getTabClass("Enteries")}
                  onClick={() => openPage("Enteries")}
                >
                  <div className="flex justify-center items-center icon-size  bg-gray-50 border border-gray-200 rounded-full dark:bg-neutral-800 dark:border-neutral-700">
                    <svg
                      className="shrink-0 size-5 text-gray-600 dark:text-neutral-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                      <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                    </svg>
                  </div>
                  <span className="ms-3">Articles</span>
                </button>
                <button
                  className={getTabClass("Subscribers")}
                  onClick={() => openPage("Subscribers")}
                >
                  <div className="flex justify-center items-center icon-size bg-gray-50 border border-gray-200 rounded-full dark:bg-neutral-800 dark:border-neutral-700">
                    <svg
                      className="shrink-0 size-5 text-gray-600 dark:text-neutral-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                      <circle cx="9" cy="7" r="4" />
                      <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    </svg>
                  </div>
                  <span className="ms-3">Subscribers</span>
                </button>
                <button
                  className={getTabClass("Settings")}
                  onClick={() => openPage("Settings")}
                >
                  <div className="flex justify-center items-center icon-size bg-gray-50 border border-gray-200 rounded-full dark:bg-neutral-800 dark:border-neutral-700">
                    <svg
                      className="shrink-0 size-5 text-gray-600 dark:text-neutral-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 7h-9" />
                      <path d="M14 17H5" />
                      <circle cx="17" cy="17" r="3" />
                      <circle cx="7" cy="7" r="3" />
                    </svg>
                  </div>
                  <span className="ms-3">Settings</span>
                </button>
                <button
                  className={getTabClass("Profile")}
                  onClick={() => openPage("Profile")}
                >
                  <div className="flex justify-center items-center icon-size bg-gray-50 border border-gray-200 rounded-full dark:bg-neutral-800 dark:border-neutral-700">
                    <svg
                      className="shrink-0 size-5 text-gray-600 dark:text-neutral-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="18" cy="15" r="3"></circle>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M10 15H6a4 4 0 0 0-4 4v2"></path>
                      <path d="m21.7 16.4-.9-.3"></path>
                      <path d="m15.2 13.9-.9-.3"></path>
                      <path d="m16.6 18.7.3-.9"></path>
                      <path d="m19.1 12.2.3-.9"></path>
                      <path d="m19.6 18.7-.4-1"></path>
                      <path d="m16.8 12.3-.4-1"></path>
                      <path d="m14.3 16.6 1-.4"></path>
                      <path d="m20.7 13.8 1-.4"></path>
                    </svg>
                  </div>
                  <span className="ms-3">Profile</span>
                </button>
              </div>
            </div>
            <div className="col-10">
              <div className="fpt_content_container">
                <div
                  id="Enteries"
                  className="tab_content"
                  style={{
                    display: activeTab === "Enteries" ? "block" : "none",
                  }}
                >
                  <div className="mb-5 text-start">
                    <Enteries userIdentity={userIdentity} />
                  </div>
                </div>

                <div
                  id="Subscribers"
                  className="tab_content"
                  style={{
                    display: activeTab === "Subscribers" ? "block" : "none",
                  }}
                >
                  <div className="mb-5">
                    <div className="">
                      <Subscribers />
                    </div>
                  </div>
                </div>

                <div
                  id="Settings"
                  className="tab_content"
                  style={{
                    display: activeTab === "Settings" ? "block" : "none",
                  }}
                >
                  <div className="mb-5 text-start">
                    <Settings />
                  </div>
                </div>

                <div
                  id="Profile"
                  className="tab_content"
                  style={{
                    display: activeTab === "Profile" ? "block" : "none",
                  }}
                >
                  <div className="mt-3">
                    <Profile userIdentity={userIdentity} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Inbox;
