import "../App.css";
import Feature from "./feature";
import Footer from "./Layout/footer";
import Inbox from "./inbox/inbox";
import { useLocation } from 'react-router-dom';

function Dashboard() {

  const valuesPassed = useLocation();
  const userIdentity = valuesPassed?.state?.userId;
  console.log('Inside Dashboard:',userIdentity);

  return (
    <div>

      <main id="content" className="shrink-0">
        <div className=" min-h-[40rem] py-10 px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8 lg:py-5 mx-auto py-2 tab-header">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link secondary-color active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Inbox
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link secondary-color "
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Explore
                </button>
              </li>
            </ul>
          </div>
          <div className="px-4 sm:px-6 lg:px-8 lg:py-5 mx-auto py-2">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <Inbox userIdentity={userIdentity} />
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 lg:py-5 mx-auto">
                  <Feature />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Dashboard;
