import { useEffect, useState } from "react";
import "../App.css";
import pilimg from "../assets/blog-badge.png";
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import axios from "axios";
function Feature() {

  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  const navigateTofeaturedetail = () => {
    navigate("/featuredetail");
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('api/posts');
        setArticles(response.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  const formatDate = (dateInSeconds) => {
    return moment(dateInSeconds*1000).format('MMM Do, YYYY');
  }

  const mintArticle = (articleId, event) => {
    event.stopPropagation();
    console.log(articleId);
  }


  return (
    <div className="">
      {/* <div className="col-12 d-flex justify-content-end">
        <div className="me-3">
          <input type="text" class="form-control" placeholder="filter by search term" />
        </div>
        <div>
          <select class="form-select" aria-label="Default select example">
            <option selected value="">Open this select menu</option>
            <option value="1">Category 1</option>
            <option value="2">Category 2</option>
            <option value="3">Category 3</option>
          </select>
        </div>
      </div> */}
      <div className="text-start">
        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
          Featured
        </h2>
        <p className="mt-1 text-gray-400 dark:text-neutral-400">
          The most interesting content collected by our team.
        </p>
      </div>

      <div className="col-12">
        <div className="row my-4">
          <div className="col-6">
            <h1 className="fw-bold my-3">Funded Project <span className="text-gray-400 dark:text-neutral-400">(sponsor and learn)</span></h1>
            <div className="row">
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Recipe/Diet</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Traditional<br />Chinese<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Western<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Others</p>
              </div>
            </div>

          </div>
          <div className="col-6">
            <h1 className="fw-bold my-3">Marketplace <span className="text-gray-400 dark:text-neutral-400">(posted by vendor/provider)</span></h1>
            <div className="row">
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Recipe/Diet</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Traditional<br />Chinese<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Western<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Others</p>
              </div>
            </div>

          </div>
        </div>
        <div className="row my-4">
          <div className="col-6">
            <h1 className="fw-bold my-3">Video <span className="text-gray-400 dark:text-neutral-400">(shared knowledge/experience)</span></h1>
            <div className="row">
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Recipe/Diet</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Traditional<br />Chinese<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Western<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Others</p>
              </div>
            </div>

          </div>
          <div className="col-6">
            <h1 className="fw-bold my-3">Referral <span className="text-gray-400 dark:text-neutral-400">(posted by client/patient)</span></h1>
            <div className="row">
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Recipe/Diet</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Traditional<br />Chinese<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Western<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Others</p>
              </div>
            </div>

          </div>
        </div>

        <div className="row my-5">
          <div className="col-6">
            <h1 className="fw-bold my-3">Writing <span className="text-gray-400 dark:text-neutral-400">(shared knowledge/experience)</span></h1>
            <div className="row">
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Recipe/Diet</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Traditional<br />Chinese<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Western<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Others</p>
              </div>
            </div>

          </div>
          <div className="col-6">
            <h1 className="fw-bold my-3">Post Your Needs <span className="text-gray-400 dark:text-neutral-400">(posted by client/patient)</span></h1>
            <div className="row">
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Recipe/Diet</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Traditional<br />Chinese<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Western<br />Medicine</p>
              </div>
              <div className="col-3">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCoUtOal33JWLqals1Wq7p6GGCnr3o-lwpQ&s" className="img-fluid" />
                <p>Others</p>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {articles.map(article =>
          <>
            <a
              onClick={navigateTofeaturedetail}
              className="group flex flex-col feature-height h-full border border-gray-200 hover:border-transparent hover:shadow-lg focus:outline-none focus:border-transparent focus:shadow-lg transition duration-300 rounded-xl dark:border-neutral-700 dark:hover:border-transparent dark:hover:shadow-black/40 dark:focus:border-transparent dark:focus:shadow-black/40"
            >
              <div></div>
              <div className="aspect-w-16 aspect-h-11">
                <img
                  className="object-cover blog-img"
                  src={`https://gateway.pinata.cloud/ipfs/${article.ipfsuri}`}
                  alt="Blog Image"
                ></img>{" "}
              </div>

              <div className="p-5">
                <div className="mb-3">
                  <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-300 dark:group-hover:text-white">
                    {article.title}
                  </h3>
                  <div className="mt-3">
                    <span className="blog-pill bg-gray-200 inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-sm font-medium border border-gray-200 bg-white text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                      <img src={pilimg} height={16} width={16} className="me-1" />
                      Funded Project
                    </span>
                    <span className="ms-2 bg-gray-100 blog-collected inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-sm font-small border border-gray-200  text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                      {formatDate(article.createdAt)}
                    </span>
                  </div>
                  <p className="mt-3 text-gray-400 dark:text-neutral-400" dangerouslySetInnerHTML={{ __html: article.content}}>
                  </p>
                </div>

                <div className="blog-profile mt-4">
                  <div className=" -space-x-3">
                    <img
                      className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                      src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                      alt="Avatar"
                    />
                    <img
                      className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                      src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                      alt="Avatar"
                    />
                    <img
                      className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                      src="https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80"
                      alt="Avatar"
                    />
                    <img
                      className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                      src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                      alt="Avatar"
                    />
                    <span className="ms-2 bg-gray-100 blog-collected inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full  font-small border border-gray-200 bg-white text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                      0 Collected
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={(event) => mintArticle(article.id, event)}
                      className="py-2 px-5 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-400 dark:bg-blue-800/30 dark:hover:bg-blue-800/20 dark:focus:bg-blue-800/20"
                    >
                      Mint
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </>
        )}


        <a
          onClick={navigateTofeaturedetail}
          className="group flex flex-col feature-height h-full border border-gray-200 hover:border-transparent hover:shadow-lg focus:outline-none focus:border-transparent focus:shadow-lg transition duration-300 rounded-xl dark:border-neutral-700 dark:hover:border-transparent dark:hover:shadow-black/40 dark:focus:border-transparent dark:focus:shadow-black/40"
        >
          <div></div>
          <div className="aspect-w-16 aspect-h-11">
            <img
              className="object-cover blog-img"
              src="https://images.unsplash.com/photo-1633114128174-2f8aa49759b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=560&q=80"
              alt="Blog Image"
            ></img>{" "}
          </div>

          <div className="p-5">
            <div className="mb-3">
              <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-300 dark:group-hover:text-white">
                Announcing a free plan for small teams
              </h3>
              <div className="mt-3">
                <span className="blog-pill bg-gray-200 inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-sm font-medium border border-gray-200 bg-white text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                  <img src={pilimg} height={16} width={16} className="me-1" />
                  Others
                </span>
                <span className="ms-2 bg-gray-100 blog-collected inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-sm font-small border border-gray-200  text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                  April 22
                </span>
              </div>
              <p className="mt-3 text-gray-400 dark:text-neutral-400">
                In 2021, after successfully crowdfunding for the Infinxte album I
                launched an entry that I continuously updated with achievements,
                milestones, metrics, and more!
              </p>
            </div>

            <div className="blog-profile mt-4">
              <div className=" -space-x-3">
                <img
                  className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                  src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                  alt="Avatar"
                />
                <img
                  className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                  src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                  alt="Avatar"
                />
                <img
                  className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                  src="https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80"
                  alt="Avatar"
                />
                <img
                  className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                  src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                  alt="Avatar"
                />
                <span className="ms-2 bg-gray-100 blog-collected inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full  font-small border border-gray-200 bg-white text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                  36 Collected
                </span>
              </div>
              <div>
                <button
                  type="button"
                  onClick={(event) => event.stopPropagation()}
                  className="py-2 px-5 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-400 dark:bg-blue-800/30 dark:hover:bg-blue-800/20 dark:focus:bg-blue-800/20"
                >
                  Mint
                </button>
              </div>
            </div>
          </div>
        </a>

      </div>

      <div className="mt-12 text-center">
        <button
          type="button"
          className="py-3 mb-5 px-4 inline-flex items-center gap-x-2 text-md font-medium rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-400 dark:bg-blue-800/30 dark:hover:bg-blue-800/20 dark:focus:bg-blue-800/20"
        >
          View More
        </button>
      </div>
    </div>
  );
}

export default Feature;
