import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import meta from "../../assets/meta.png";
import { useWeb3React } from "@web3-react/core";
import detectEthereumProvider from "@metamask/detect-provider";
import axios from 'axios';

function Header() {


  const { connector, hooks } = useWeb3React();
  const { useSelectedAccount } = hooks;
  let account = useSelectedAccount(connector);
  const [isConnected, setIsConnected] = useState(false);
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  const onConnectMetaMask = async () => {

    console.log("Connecting to Metamask");
    if (account) {

      const closeButton = document.querySelector(".modal-close");
      if (closeButton) {
        closeButton.click();
      }
      
      return;

    } else {

      const chainId = process.env.SUPPORT_CHAIN_ID || "5";
      try {
        await connector.activate(chainId);
        account = 1;
        const closeButton = document.querySelector(".modal-close");
        if (closeButton) {
          closeButton.click();
        }
        navigate("/dashboard");
      } catch (err) {
        console.log("User rejected the request", err);
      }

    }

  };

  const onDisconnectMetaMask = () => {
    console.log("Disconnecting Metamask");
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    setIsConnected(false);
    setUserId('');
    navigateToHome();
  };

  const navigateToHome = () => {
    navigate("/");
  };

  const navigateToWrite = () => {
    navigate("/write",{ state: { userId:  userId} });
  };

  const handleCreateEntry = async () => {
    const provider = await detectEthereumProvider();
    if (provider) {
      try {

        // Send the wallet address to the backend
        const response = await axios.post(`api/users`, { walletAddress: account });
        console.log('User created:', response.data);
        console.log('Wallet connected:', account);
        if (response.status === 200) {
          setIsConnected(true);
          setUserId(response.data.id);
          navigate("/dashboard",{ state: { userId:  response.data.id} });
          // setUserIdFromHeader(response.data.id);
        }
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    } else {
      console.error('MetaMask provider not found');
    }
  };

  useEffect(() => {
    const fetchUserName = async () => {
      if (userId) {
        console.log(`Fetching user name`);
        try {
          const response = await axios.get(`api/users/${userId}`);
          if (response.status === 200) {
            setUserName(response.data.name ?? 'Guest');
            console.log('User created:', response.data);
          }
        } catch (error) {
          console.error('Error fetching user name:', error);
        }
      }
    };

    fetchUserName();
  }, [userId]);

  useEffect(() => {
    if (window.ethereum) {

      // Function to handle account change
      const handleAccountsChanged = async () => {
        console.log('handleAccountsChanged called');
        if (account) {
          await handleCreateEntry();
        } else {
          setIsConnected(false);
          setUserId('');
          // setUserIdFromHeader('');
          navigateToHome();
        }
      };

      // Function to handle network change
      const handleChainChanged = () => {
        console.log('handleChainChanged called');
        window.location.reload();
      };

      // Add event listeners
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);

      // Request accounts to connect on component mount
      window.ethereum
        .request({ method: 'eth_accounts' })
        .then(handleAccountsChanged)
        .catch((err) => {
          console.error('Error fetching accounts:', err);
        });

      // Cleanup function to remove event listeners
      return () => {
        if (window.ethereum.removeListener) {
          window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
          window.ethereum.removeListener('chainChanged', handleChainChanged);
        }
      };
    } else {
      console.error('MetaMask wallet not found');
    }
  }, [account]);

  return (
    <header className="flex flex-wrap md:justify-start md:flex-nowrap z-50 py-3 header-custom">
      <nav className="relative max-w-9xl max-w-[85rem]  mx-auto px-4 flex flex-wrap md:grid md:grid-cols-12 basis-full items-center px-3 md:px-3 md:px-3 mx-auto">
        <div className="md:col-span-6" onClick={navigateToHome}>
          {/* <img src={logo} height={50} width={50} className="logo" /> */}
          <a
            className="flex-none rounded-xl text-xl inline-block font-bold focus:outline-none pe-cursor focus:opacity-80 logo-text"
            aria-label="Preline"
          >
            <span className="title-color pe-cursor">Renewal DAO</span>
          </a>
        </div>
        <div className="flex items-center gap-x-1 md:gap-x-2 ms-auto py-1 md:ps-6  md:col-span-6">
          {!isConnected ?
            <button
              type="button"
              className="btn-connect visible py-2 px-4 inline-flex items-center font-medium text-md rounded-lg text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:bg-blue-600"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Connect
            </button>
            :
            <>
              <div className="dropdown">

                <img className="profile-img visible rounded-full dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" src="https://images.unsplash.com/photo-1669837401587-f9a4cfe3126e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" alt="Avatar" />

                <ul className="dropdown-menu mt-2">
                  <li><a className="dropdown-item" href="#">View Profile</a></li>
                  <li onClick={onDisconnectMetaMask}><a className="dropdown-item" href="#">Disconnect</a></li>
                </ul>
              </div>

              <button
                onClick={navigateToWrite}
                type="button"
                className="btn-connect visible  py-2 px-4 inline-flex items-center font-medium text-md rounded-lg text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:bg-blue-600"
              >
                Create
              </button>

            </>
          }
        </div>
        <div
          className={`modal fade font-medium`}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1
                  className="modal-title fs-5 text-gray-800"
                  id="exampleModalLabel"
                >
                  Connect to Wallet
                </h1>
                <button
                  type="button"
                  className="btn-close modal-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <button
                  type="button"
                  onClick={onConnectMetaMask}
                  className="jc-space w-100 py-3 px-4 inline-flex items-center gap-x-2 text-lg font-md rounded-lg border border-transparent bg-gray-300 text-black hover:bg-gray-300 focus:outline-none focus:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none"
                >
                  Metamask
                  <img
                    src={meta}
                    height={32}
                    width={32}
                    className="float-end"
                  />
                </button>
                <p className="text-sm font-sm text-gray-400 mt-4 mb-2 text-center px-3">
                  By connecting your wallet you agree to the{" "}
                  <span className="text-gray-500">Terms of Service</span> and{" "}
                  <span className="text-gray-500">Privacy Policy</span>
                </p>
              </div>
            </div>
          </div>
        </div>

      </nav>
    </header>
  );
}

export default Header;
