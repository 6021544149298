function Footer() {
  return (
    <footer className="mt-5 w-full  px-4 sm:px-6 lg:px-8 mx-auto position-fixed footer-custom">
      <div className="text-center">
        {/* <div>
          <a className="flex-none" href="#" aria-label="Brand">
            <img src={logo} height={50} width={50} className='logo' />
          </a>
        </div> */}
        <div className="mt-3 mb-3">
          <span className="text-gray-500 dark:text-neutral-500">
            Learn more
          </span>
          <span className="text-gray-500 dark:text-neutral-500 ms-3">Blog</span>
          <span className="text-gray-500 dark:text-neutral-500 ms-3">
            Hiring
          </span>
          <span className="text-gray-500 dark:text-neutral-500 ms-3">
            Support
          </span>
          <span className="text-gray-500 dark:text-neutral-500 ms-3">
            Privacy
          </span>
          <span className="text-gray-500 dark:text-neutral-500 ms-3">
            Terms & Conditions
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
