import "../../App.css";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';

function Entries({ userIdentity }) {

  const navigate = useNavigate();

  const handleRowClick = (row) => {
    navigate('/write', { state: { userIdentity: row.userId, article: { content: row.content, id: row.id, ipfsuri: row.ipfsuri, title: row.title } } });
  };

  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [draftArticles, setDraftArticles] = useState([]);
  const [filter, setFilter] = useState('all');

  const handleFilter = (filterType) => {
    setFilter(filterType);
    if (filterType === 'collected') {
      setFilteredArticles(articles.filter(article => article.status === 'collected'));
    } else if (filterType === 'published') {
      setFilteredArticles(articles.filter(article => article.status === 'published'));
    } else {
      setFilteredArticles(articles); // Show all articles
    }
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('api/inbox', {
          params: { userId: userIdentity }
        });
        const fetchedArticles = response.data?.filter(article => article.status === 'collected' || article.status === 'published')
        setArticles(fetchedArticles);
        setFilteredArticles(fetchedArticles);
        setFilter('all');
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    const fetchDraftArticles = async () => {
      try {
        const response = await axios.get('api/articles/drafts', {
          params: { userId: userIdentity }
        });
        setDraftArticles(response.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    if (userIdentity) {
      fetchArticles();
      fetchDraftArticles();
    }
  }, [userIdentity]);



  const columns = [
    {
      selector: row => <img className="table-img" src={`https://gateway.pinata.cloud/ipfs/${row.ipfsuri}`} alt={row.title} width={50} height={50} />,
      sortable: false,
      maxWidth: '100px !important',
    },
    {
      selector: row => (
        <div onClick={(row) => handleRowClick(row)}>
          <div style={{ fontWeight: 'bold' }}>{row.title}</div>
          <div className="mt-2">{row.content}</div>
        </div>
      ),
      sortable: false,
    },
    {
      selector: row => (
        <button
          style={{
            backgroundColor: getStatusColor(row.status),
            color: 'white',
            border: 'none',
            padding: '8px 15px',
            borderRadius: '7px',
            cursor: 'pointer',
            fontWeight: '500',
            width: '100px'
          }}
        >
          {row.status}
        </button>
      ),
      sortable: true,

      maxWidth: '150px !important',
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case 'published':
        return '#14b8a6';
      case 'draft':
        return '#eab308';
      case 'collected':
        return 'blue';
      default:
        return 'grey';
    }
  };


  return (
    <div>

      <div className="px-4 sm:px-6 lg:px-8 lg:py-5 mx-auto py-0 b-l m-h-600">
        <ul className="nav nav-underline" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation" onClick={() => handleFilter('all')}>
            <button
              className="nav-link secondary-color active px-3"
              id="pills-all-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-all"
              type="button"
              role="tab"
              aria-controls="pills-all"
              aria-selected="true"
            >
              All
            </button>
          </li>
          <li className="nav-item" role="presentation" onClick={() => handleFilter('published')}>
            <button
              className="nav-link secondary-color px-3"
              id="pills-published-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-published"
              type="button"
              role="tab"
              aria-controls="pills-published"
              aria-selected="false"
            >
              Published
            </button>
          </li>
          <li className="nav-item" role="presentation" onClick={() => handleFilter('collected')}>
            <button
              className="nav-link secondary-color px-3"
              id="pills-collected-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-collected"
              type="button"
              role="tab"
              aria-controls="pills-collected"
              aria-selected="false"
            >
              Collected
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link secondary-color px-3"
              id="pills-draft-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-draft"
              type="button"
              role="tab"
              aria-controls="pills-draft"
              aria-selected="false"
            >
              Draft
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active mb-3"
            id="pills-all"
            role="tabpanel"
            aria-labelledby="pills-all-tab"
          >
            <DataTable
              columns={columns}
              data={filteredArticles}
              pagination
            />
          </div>
          <div
            className="tab-pane fade mb-3"
            id="pills-published"
            role="tabpanel"
            aria-labelledby="pills-published-tab"
          >
            <DataTable
              columns={columns}
              data={filteredArticles}
              pagination
            />
          </div>
          <div
            className="tab-pane fade mb-3"
            id="pills-collected"
            role="tabpanel"
            aria-labelledby="pills-collected-tab"
          >
            <DataTable
              columns={columns}
              data={filteredArticles}
              pagination
            />
          </div>
          <div
            className="tab-pane fade mb-3"
            id="pills-draft"
            role="tabpanel"
            aria-labelledby="pills-draft-tab"
          >
            <DataTable
              columns={columns}
              data={draftArticles}
              pagination
              onRowClicked={handleRowClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Entries;
