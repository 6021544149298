import Footer from "./Layout/footer";
import { useLocation, useNavigate } from "react-router-dom";
import leftarrow from "../assets/arrow-left.svg";

function FeatureDetail() {

    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate("/");
    };

    return (
        <div>

            <div class="max-w-3xl px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
                <div class="max-w-2xl">


                    <div class="space-y-5 md:space-y-8">
                        <button type="button" className="btn btn-secondary pe-4" onClick={navigateToHome}>
                            <a class="back d-flex" >
                                <img className="me-1 left-arrow" src={leftarrow} height={14} width={14} /><span>Back</span></a>
                        </button>

                        <figure>
                            <img class="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1670272505340-d906d8d77d03?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=560&q=80" alt="Blog Image" />

                        </figure>


                        <div class="space-y-3">
                            <h2 class=" font-bold  dark:text-white feature-title">Crypto The Game Season 2 Recap, Reunion, and Confessions</h2>

                            <div class="flex justify-between items-center mb-6">
                                <div class="flex my-3 w-full sm:items-center gap-x-5 sm:gap-x-3">
                                    <div class="shrink-0">
                                        <img class="size-12 rounded-full" src="https://images.unsplash.com/photo-1669837401587-f9a4cfe3126e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" alt="Avatar" />
                                    </div>

                                    <div class="grow">
                                        <div class="flex items-center gap-x-2">
                                            <div>
                                                <div class="hs-tooltip [--trigger:hover] [--placement:bottom] inline-block">
                                                    <div class="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                                                        <span class="font-semibold text-gray-800 dark:text-neutral-200">

                                                            Rehash: A Web3 Podcast

                                                        </span>

                                                        <div class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 max-w-xs cursor-default bg-gray-900 divide-y divide-gray-700 shadow-lg rounded-xl dark:bg-neutral-950 dark:divide-neutral-700" role="tooltip">
                                                            <div class="p-4 sm:p-5">
                                                                <div class="mb-2 flex w-full sm:items-center gap-x-5 sm:gap-x-3">
                                                                    <div class="shrink-0">
                                                                        <img class="size-8 rounded-full" src="https://images.unsplash.com/photo-1669837401587-f9a4cfe3126e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80" alt="Avatar" />
                                                                    </div>

                                                                    <div class="grow">
                                                                        <p class="text-lg font-semibold text-gray-200 dark:text-neutral-200">
                                                                            Leyla Ludic
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <p class="text-sm text-gray-400 dark:text-neutral-400">
                                                                    Leyla is a Customer Success Specialist at Preline and spends her time speaking to in-house recruiters all over the world.
                                                                </p>
                                                            </div>

                                                            <div class="flex justify-between items-center px-4 py-3 sm:px-5">
                                                                <ul class="text-xs space-x-3">
                                                                    <li class="inline-block">
                                                                        <span class="font-semibold text-gray-200 dark:text-neutral-200">56</span>
                                                                        <span class="text-gray-400 dark:text-neutral-400">articles</span>
                                                                    </li>
                                                                    <li class="inline-block">
                                                                        <span class="font-semibold text-gray-200 dark:text-neutral-200">1k+</span>
                                                                        <span class="text-gray-400 dark:text-neutral-400">followers</span>
                                                                    </li>
                                                                </ul>

                                                                <div>
                                                                    <button type="button" class="py-1.5 px-2.5 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                                                                        <svg class="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                                            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                                                                        </svg>
                                                                        Follow
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                            <div>
                                                <span className="ms-2 bg-gray-100  inline-flex items-center gap-x-1.5 py-1 px-2 rounded-full text-xs font-small border border-gray-200 text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                                                    0X4B05
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="flex  items-center mb-6">
                                <div class="flex  w-full j-c-s sm:items-center gap-x-5 sm:gap-x-3">
                                    <div>
                                        <span className="ms-2 bg-gray-100  inline-flex items-center gap-x-1.5 py-1 px-2 rounded-full text-xs font-small border border-gray-200 text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                                            April 22nd, 2024
                                        </span>

                                    </div>

                                    <div>
                                        <button
                                            type="button"
                                            className="py-2 px-5 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-400 dark:bg-blue-800/30 dark:hover:bg-blue-800/20 dark:focus:bg-blue-800/20"
                                        >
                                            Mint
                                        </button>
                                    </div>


                                    <div class="hs-tooltip [--trigger:hover] [--placement:bottom] inline-block">
                                        <div class="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                                            <div className="blog-profile float-end mt-2">
                                                <div className=" -space-x-3">
                                                    <img
                                                        className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                                                        src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                                                        alt="Avatar"
                                                    />
                                                    <img
                                                        className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                                                        src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                                                        alt="Avatar"
                                                    />
                                                    <img
                                                        className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                                                        src="https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80"
                                                        alt="Avatar"
                                                    />
                                                    <img
                                                        className="inline-block size-6 rounded-full ring-2 ring-white dark:ring-neutral-900"
                                                        src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                                                        alt="Avatar"
                                                    />
                                                    <span className="ms-2 bg-gray-100 blog-collected inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full  font-small border border-gray-200 bg-white text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                                                        36 Collected
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="badges mt-4">
                                <span className="ms-2 bg-gray-100  inline-flex items-center gap-x-1.5 py-1 px-2 rounded-full text-xs font-small border border-gray-200 text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                                    Technology
                                </span>
                                <span className="ms-2 bg-gray-100  inline-flex items-center gap-x-1.5 py-1 px-2 rounded-full text-xs font-small border border-gray-200 text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                                    Science
                                </span>
                                <span className="ms-2 bg-gray-100  inline-flex items-center gap-x-1.5 py-1 px-2 rounded-full text-xs font-small border border-gray-200 text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                                    Crpto
                                </span>
                                <span className="ms-2 bg-gray-100  inline-flex items-center gap-x-1.5 py-1 px-2 rounded-full text-xs font-small border border-gray-200 text-gray-400 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white">
                                    Bitcoin
                                </span>
                            </div>

                        </div>




                        <figure>
                            <img class="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1670272498380-eb330b61f3cd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=560&q=80" alt="Blog Image" />
                            <figcaption class="mt-3 text-sm text-center text-gray-500 dark:text-neutral-500">
                                A man and a woman looking at a cell phone.
                            </figcaption>
                        </figure>

                        <div class="space-y-3">
                            <h3 class="text-2xl font-semibold dark:text-white">Bringing the culture of sharing to everyone</h3>

                            <p class="text-lg text-gray-800 dark:text-neutral-200">We know the power of sharing is real, and we want to create an opportunity for everyone to try Preline and explore how transformative open communication can be. Now you can have a team of one or two designers and unlimited spectators (think PMs, management, marketing, etc.) share work and explore the design process earlier.</p>
                        </div>

                        <ul class="list-disc list-outside space-y-5 ps-5 text-lg text-gray-800 dark:text-neutral-200">
                            <li class="ps-2">Preline allows us to collaborate in real time and is a really great way for leadership on the team to stay up-to-date with what everybody is working on," <a class="text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500" href="#">said</a> Stewart Scott-Curran, Intercom's Director of Brand Design.</li>
                            <li class="ps-2">Preline opened a new way of sharing. It's a persistent way for everyone to see and absorb each other's work," said David Scott, Creative Director at <a class="text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500" href="#">Eventbrite</a>.</li>
                        </ul>

                        <p class="text-lg text-gray-800 dark:text-neutral-200">Small teams and individual designers need a space where they can watch the design process unfold, both for themselves and for the people they work with – no matter if it's a fellow designer, product manager, developer or client. Preline allows you to invite more people into the process, creating a central place for conversation around design. As those teams grow, transparency and collaboration becomes integrated in how they communicate and work together.</p>

                        <div className="text-center">
                            <button className="btn btn-primary mx-auto px-4 py-2 font-bold mb-5">Subscribe</button>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default FeatureDetail;
