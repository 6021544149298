import "../App.css";
import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { ImageDrop } from "quill-image-drop-module";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Layout/footer";
import axios from 'axios';
import Web3 from 'web3';
import ArticleNFTABI from '../nft_article_abi.json';
import env from "react-dotenv";


Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

function Write() {

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [article, setArticle] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [createInProgress, setCreateInProgress] = useState(false);
  const [userIdentity, setUserIdentity] = useState('');
  const valuesPassed = useLocation();
  const userId = valuesPassed?.state?.userId;
  

  console.log('The Values from header : ',valuesPassed);
  useEffect(() => {
    if (userId) {
      setUserIdentity(userId);
    }
  }, [userId]);

  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate("/dashboard",{ state: { userId:  userId} });
  };

  const handleCreateArticle = async () => {
    setCreateInProgress(true);
    const formData = new FormData();
    formData.append('coverImage', coverImage);
    formData.append('title', title);
    formData.append('content', content);
    formData.append('userId', userIdentity);

    try {
      const response = await axios.post('api/articles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200 || response.status === 201) {
        setArticle(response.data);
        console.log('Article created:', response.data);
        setCreateInProgress(false);
        alert('Article created. Please publish the article');
      }
    } catch (error) {
      console.error('Error creating article:', error);
      setCreateInProgress(false);
    }
  };

  const createNFT = async () => {
    if (!contract || !account) {
      console.error('Contract or account not initialized');
      return;
    }

    const tokenURI = JSON.stringify({
      title: article.title,
      content: article.content,
      image: article.ipfsuri
    });

    try {

      const gasPrice = await web3.eth.getGasPrice();
      const amount = 5;
      const price = web3.utils.toWei('0.000000000000001', 'ether');

      const receipt = await contract.methods.createToken(tokenURI, amount, price, 0).send({
        from: account,
        gasPrice: gasPrice // Ensure to use gasPrice for networks not supporting EIP-1559
      });

      console.log('NFT minted successfully:', receipt);

      if (receipt.transactionHash) {
        try {
          const transaction = await web3.eth.getTransaction(receipt.transactionHash);
          const nonce = transaction.nonce;
          console.log('Nonce for transaction:', nonce.toString());

          // Store the transaction hash and nonce in the backend
          await storeReceipt(receipt.transactionHash, userIdentity, article.id, nonce.toString()); // Modify this to store the nonce
          navigate('/dashboard');
        } catch (txError) {
          console.error('Error fetching transaction details:', txError);
        }
      }
    } catch (error) {
      console.error('Error minting NFT:', error);
    }
  };

  const storeReceipt = async (transactionHash, userId, articleId, nonce) => {
    try {
      const response = await axios.post('api/articles/store-receipt', {
        transactionHash,
        userId,
        articleId,
        nonce
      });
      console.log('Receipt stored successfully:', response.data);
    } catch (error) {
      console.error('Error storing receipt:', error);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      setWeb3(web3);
      window.ethereum.enable().then(accounts => {
        setAccount(accounts[0]);
        const contract = new web3.eth.Contract(ArticleNFTABI, "0xd1c65543f3f2e7d44318f360dea6dfb607d2916d"); // Deployed in Sepolia Testnet
        setContract(contract);
      });
    } else {
      console.error('MetaMask not found');
    }

    if (valuesPassed?.state?.article) {
      setArticle(valuesPassed?.state?.article);
      setTitle(valuesPassed?.state?.article.title);
      setContent(valuesPassed?.state?.article.content);
      setCoverImage(valuesPassed?.state?.article.coverImage);
    }
  }, []);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    imageResize: {
      // Object for image resizing configurations.
    },
    imageDrop: true,
  };

  return (
    <div className="">
      <main id="content" className="shrink-0 bg-write">
        <div className="max-w-[85rem] min-h-[40rem] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="items-center py-3">

            {article && article.id ? <>
              <button
                onClick={createNFT}
                type="button"
                className="btn-publish float-end  py-2 px-4 inline-flex items-center font-medium text-md rounded-lg    disabled:opacity-50 disabled:pointer-events-none "
              >
                Publish
              </button>

              <button
                type="button"
                className=" float-end ms-3 me-3  py-2 px-4 inline-flex items-center font-medium text-md rounded-lg bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:bg-red-600 disabled:opacity-50 disabled:pointer-events-none "
              >
                Delete
              </button>
            </>
              : <></>
            }
          </div>

          <div className="mt-7  dark:bg-neutral-900 dark:border-neutral-700">
            <div className="mt-5">
              <div className="mt-2">
                <form>
                  <div className="grid gap-y-4">
                    <div>
                      <label className="block text-lg mb-2 dark:text-white">
                        Enter title
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="title"
                          name="title"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          required
                          aria-describedby="email-error"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <p
                        className="hidden text-xs text-red-600 mt-2"
                        id="title-error"
                      >
                        Please include a title
                      </p>
                    </div>

                    <div className="my-2 mb-4">
                      <label className="block text-lg mb-2 dark:text-white">
                        Display Image
                      </label>
                      <div className="relative">
                        <input
                          type="file"
                          id="img"
                          name="img"
                          className="py-3 px-4 block bg-white w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                          required
                          aria-describedby="img-error"
                          // value={coverImage}
                          onChange={(e) => setCoverImage(e.target.files[0])}
                        />
                      </div>

                    </div>

                    <div className="my-2 mb-4">
                      <label className="block text-lg mb-2 dark:text-white">
                        Enter content
                      </label>
                      <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={modules}
                      />
                    </div>

                    <div className="text-end">
                      {createInProgress ? <></>
                        : <button
                          onClick={navigateToDashboard}
                          type="button"
                          className="me-3 py-2 px-3 inline-flex items-center gap-x-2 text-md font-medium rounded-lg border border-transparent bg-gray-500 text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 disabled:opacity-50 disabled:pointer-events-none"
                        >
                          Cancel
                        </button>
                      }

                      <button
                        onClick={handleCreateArticle}
                        type="button"
                        className="py-2 px-3 btn-connect inline-flex items-center gap-x-2 text-md font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      >
                        {createInProgress ? "Saving..." : "Save draft"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Write;
