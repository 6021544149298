import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";

function Subscribers() {

  const navigate = useNavigate();

  const handleRowClick = (row) => {
  //   console.log('Row clicked:', row); 
  // navigate(`/write/${row.id}`);
  };

  
  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Bio',
      selector: row => row.address,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
    },
  ];

  const data = [
    {
      id: 1,
      name: 'John Doe',
      address: 'Test Bio',
      email: 'john.doe@example.com',
      date: '2024-08-30',
    },
    {
      id: 2,
      name: 'Jane Smith',
      address: 'Sample Bio 2',
      email: 'jane.smith@example.com',
      date: '2024-08-29',
    },
    {
      id: 3,
      name: 'Alice Johnson',
      address: 'Sample Bio',
      email: 'alice.johnson@example.com',
      date: '2024-08-28',
    },
    // Add more data as needed
  ];

  return (
    <div className="px-4 sm:px-6 lg:px-8 lg:py-5 mx-auto py-2 b-l m-h-600">
      <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-300 dark:group-hover:text-white">
        Subscribers
      </h3>
      <div className=" mt-3">
      <DataTable
      columns={columns}
      data={data}
      pagination
      onRowClicked={handleRowClick}
    />
      </div>
    </div>
  );
}

export default Subscribers;
